"use strict";

var _effects = require("redux-saga/effects");

var _reduxSaga = require("redux-saga");

var _omit = _interopRequireDefault(require("lodash/omit"));

var _pick = _interopRequireDefault(require("lodash/pick"));

var _get = _interopRequireDefault(require("lodash/get"));

var _tools = require("@23point5/core/controls/utils/tools");

var _cloneDeep = _interopRequireDefault(require("lodash/cloneDeep"));

var _store = _interopRequireDefault(require("store2"));

var _v = _interopRequireDefault(require("uuid/v4"));

var _artistOmittedFields = _interopRequireDefault(require("@common/statics/artistOmittedFields"));

var _formData = _interopRequireDefault(require("form-data"));

var _logrocket = _interopRequireDefault(require("logrocket"));

var _history = _interopRequireDefault(require("@23point5/core/controls/history"));

var _tools2 = require("@common/utils/tools");

var _actions = require("@App/actions");

var _uploadFile = _interopRequireDefault(require("@utils/uploadFile"));

var _multipleUpload = _interopRequireDefault(require("@utils/multipleUpload"));

var _contexts = require("@pages/Session/contexts");

var _axios = _interopRequireDefault(require("../../utils/axios"));

var _types = require("../types");

var _getMetadata = _interopRequireDefault(require("@utils/getMetadata"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

const sessionEntity = 'artist';

const delay2 = ms => new Promise(res => setTimeout(res, ms));

function* GetSessionCompanyDetails({
  payload
}) {
  const id = payload.id;
  const config = {
    method: 'get',
    url: `/company/${id}`
  };
  const result = yield (0, _effects.call)(_axios.default, config, _types.GET_SESSION_COMPANY_DETAILS);

  if (result.type === _types.GET_SESSION_COMPANY_DETAILS) {
    yield (0, _effects.all)([(0, _effects.put)({
      type: _types.SET_DIALOG_DATA,
      payload: result.payload
    }), (0, _effects.put)({
      type: _types.RELOAD_DIALOG
    })]);
  } else {
    yield (0, _effects.put)(result);
  }
}

function* LoginUser({
  payload
}) {
  yield (0, _effects.put)({
    type: _types.CLEAR_ERROR
  });
  yield (0, _effects.put)({
    type: _types.CLEAR_TOASTS
  });
  yield (0, _effects.put)({
    type: _types.PROCESSING_USER
  });

  const _payload = _objectSpread({}, payload),
        _payload$callback = _payload.callback,
        callback = _payload$callback === void 0 ? () => {} : _payload$callback,
        _payload$isHideDialog = _payload.isHideDialog,
        isHideDialog = _payload$isHideDialog === void 0 ? true : _payload$isHideDialog,
        _payload$onHideDialog = _payload.onHideDialog,
        onHideDialog = _payload$onHideDialog === void 0 ? () => {} : _payload$onHideDialog,
        _payload$isShowSucces = _payload.isShowSuccessToast,
        isShowSuccessToast = _payload$isShowSucces === void 0 ? true : _payload$isShowSucces,
        _payload$errorCallbac = _payload.errorCallback,
        errorCallback = _payload$errorCallbac === void 0 ? () => {} : _payload$errorCallbac;

  const metadata = (0, _getMetadata.default)();
  const session_params = {
    method: 'post',
    url: `/${sessionEntity}/login`,
    data: _objectSpread(_objectSpread({}, (0, _omit.default)(payload, ['callback', 'isHideDialog', 'onHideDialog', 'isShowSuccessToast', 'shopifyClient'])), {}, {
      device_type: metadata.platform,
      metadata: metadata
    })
  };
  const session = yield (0, _effects.call)(_axios.default, session_params, _types.LOGIN_USER);

  if (session.type !== _types.LOGIN_USER) {
    yield (0, _effects.put)({
      type: _types.CLEAR_ERROR
    });
    yield (0, _effects.put)({
      type: _types.CLEAR_TOASTS
    });
    yield (0, _reduxSaga.delay)(100);
    yield (0, _effects.put)(session);
    yield (0, _effects.put)({
      type: _types.PROCESSING_USER_DONE
    });
    const _session$payload = session.payload,
          errorPayload = _session$payload === void 0 ? {} : _session$payload;
    const message = errorPayload.message; // yield put(ShowError({ message }))

    if (errorCallback) {
      errorCallback({
        errorMessage: message || 'Oops!, something went wrong. Please try again.'
      });
    }

    return;
  }

  const generateProductNameConfig = {
    method: 'POST',
    url: `/generaete-product-name`,
    data: {
      artist_id: session.payload.id
    }
  };
  const genereteProductNameResult = yield (0, _effects.call)(_axios.default, generateProductNameConfig, "GENERATE_PRODUCT_NAME_POST_AUTH");
  let generatedProductName = '';

  if (genereteProductNameResult.type === "GENERATE_PRODUCT_NAME_POST_AUTH" && genereteProductNameResult.payload.name) {
    generatedProductName = genereteProductNameResult.payload.name;
  }

  if (session.type === _types.LOGIN_USER) {
    // Retrieve artist cartt after signin
    yield (0, _effects.put)({
      type: 'MERGE_LOCAL_CART_TO_ACCOUNT_REQUESTED',
      payload: {
        session: session.payload,
        shopifyClient: payload.shopifyClient
      }
    });
    yield (0, _effects.put)({
      payload: {
        data: session.payload,
        languages: [{
          code: 'en',
          default: true,
          id: '7aa9d5f3-e493-49b5-b468-75ae53e3cb5e',
          name: 'English'
        }]
      },
      type: _types.LOGIN_USER
    });

    if (isHideDialog) {
      yield (0, _effects.put)((0, _actions.HideDialog)());
    }

    if (callback) {
      callback(session.payload, onHideDialog, generatedProductName);
    }

    yield (0, _effects.put)({
      type: _types.PROCESSING_USER_DONE
    });

    if (isShowSuccessToast) {
      yield (0, _effects.put)((0, _actions.ShowSuccess)({
        customMessage: `Welcome! We hope you'll enjoy using 23point5.`
      }));
    }

    _logrocket.default.identify(session.payload.id, {
      name: (0, _tools2.formatPersonName)(session.payload.first_name, session.payload.last_name),
      email: session.payload.email
    }); // if (!session.payload.is_verified) {
    //   yield put({
    //     type: 'SHOW_DIALOG_REQUESTED',
    //     payload: {
    //       uniqueEntity: 'post-auth-notification',
    //       context: sessionDialogContext.postAuthNotification,
    //       data: {
    //         is_verified: session.payload.is_verified
    //       }
    //     }
    //   })
    // }


    const history = (0, _history.default)();

    if (session.response.headers.rurl) {
      console.log('session.response.headers.rurl: ', session.response.headers.rurl);
      const headers = session.response.headers;

      if (headers['rurl-type'] === 'success') {
        history.push(headers.rurl);
        yield (0, _reduxSaga.delay)(4000);
        yield (0, _effects.put)((0, _actions.ShowSuccess)({
          message: `Shopify Shop Link Successful`
        }));
      } else {
        // history.push(headers.rurl)
        window.location.href = history.push(headers.rurl);
        yield (0, _reduxSaga.delay)(4000);
        yield (0, _effects.put)((0, _actions.ShowError)({
          message: window.atob(headers['rurl-message'])
        }));
      }
    }
  } else {
    yield (0, _effects.put)(session);

    if (callback) {
      callback({}, onHideDialog, generatedProductName);
    }
  }
}

function* RegisterUser({
  payload
}) {
  const _payload$isHideDialog2 = payload.isHideDialog,
        isHideDialog = _payload$isHideDialog2 === void 0 ? true : _payload$isHideDialog2,
        callback = payload.callback,
        errorCallback = payload.errorCallback,
        _payload$onHideDialog2 = payload.onHideDialog,
        onHideDialog = _payload$onHideDialog2 === void 0 ? () => {} : _payload$onHideDialog2,
        postDispatches = payload.postDispatches;
  const history = (0, _history.default)();
  yield (0, _effects.put)({
    type: _types.CLEAR_ERROR
  });
  yield (0, _effects.put)({
    type: _types.CLEAR_TOASTS
  });
  yield (0, _effects.put)({
    type: _types.PROCESSING_USER
  });

  const data = _objectSpread(_objectSpread({}, (0, _omit.default)(payload, ['password2', 'company_name', 'isHideDialog', 'callback', 'onHideDialog', 'isBeAnArtist', 'access_token', 'authenticated', 'placeholder_blob', 'postDispatches', 'new_account'])), {}, {
    plans: {
      '23point5': true,
      shopify: false
    },
    metadata: {},
    is_customer: false,
    has_placeholder_image: true
  });

  const config = {
    method: 'post',
    url: `/${sessionEntity}/signup`,
    data
  };
  const result = yield (0, _effects.call)(_axios.default, config, _types.REGISTER_USER);

  if (result.type !== _types.REGISTER_USER) {
    if (errorCallback) {
      errorCallback();
    }

    yield (0, _effects.put)({
      type: _types.CLEAR_ERROR
    });
    yield (0, _effects.put)({
      type: _types.CLEAR_TOASTS
    });
    yield (0, _reduxSaga.delay)(100);
    yield (0, _effects.put)(result);
    yield (0, _effects.put)({
      type: _types.PROCESSING_USER_DONE
    });
    return;
  } // const placeholder_file = new File(
  //   [payload.placeholder_blob],
  //   `${result.payload.id}-${uuid()}.png`,
  //   {
  //     type: 'png',
  //     id: uuid()
  //   }
  // )
  // const uploadPlaceholderConfig = {
  //   files: [placeholder_file],
  //   uniqueEntity: 'artist',
  //   entity_id: result.payload.id,
  //   chunking: {
  //     partSize: 5000000,
  //     concurrent: {
  //       enabled: true
  //     }
  //   }
  // }
  // yield call(uploadFile, uploadPlaceholderConfig)


  const metadata = (0, _getMetadata.default)(); // login user

  const session_params = {
    method: 'post',
    url: `/${sessionEntity}/login`,
    data: _objectSpread(_objectSpread({}, config.data), {}, {
      device_type: metadata.platform,
      metadata: metadata,
      source_action: 'post_creation'
    })
  };
  const session = yield (0, _effects.call)(_axios.default, session_params, _types.LOGIN_USER);

  if (session.type === _types.LOGIN_USER) {
    // yield put(UpdateSessionData(session.payload));
    yield (0, _effects.put)({
      type: _types.LOGIN_USER,
      payload: {
        data: session.payload
      }
    });
    yield (0, _effects.put)((0, _actions.ShowSuccess)({
      message: `Sign Up Successful, Email verification has been sent.`
    }));

    if (isHideDialog) {
      yield (0, _effects.put)((0, _actions.HideDialog)());
    } // yield call(delay, 3000)=


    const promises = [];

    if (postDispatches) {
      const chainFunctions = (0, _tools.flowAsync)(postDispatches.filter(e => typeof e === 'function'));
      const chainDispatches = postDispatches.filter(e => e.type).map(e => (0, _effects.put)(e));
      promises.push(...chainDispatches);
      yield (0, _effects.call)(() => chainFunctions());
    }

    yield (0, _effects.all)(promises);
    const generateProductNameConfig = {
      method: 'POST',
      url: `/generaete-product-name`,
      data: {
        artist_id: result.payload.id
      }
    };
    const genereteProductNameResult = yield (0, _effects.call)(_axios.default, generateProductNameConfig, "GENERATE_PRODUCT_NAME_POST_AUTH");
    let generatedProductName = '';

    if (genereteProductNameResult.type === "GENERATE_PRODUCT_NAME_POST_AUTH" && genereteProductNameResult.payload.name) {
      generatedProductName = genereteProductNameResult.payload.name;
    }

    if (callback && !result.response.headers.rurl) {
      callback(session.payload, onHideDialog, generatedProductName);
    }

    yield (0, _effects.put)({
      type: _types.PROCESSING_USER_DONE
    }); // if (!payload.new_account) {
    //   yield call(delay, 500)
    //   yield put({
    //     type: 'SHOW_DIALOG_REQUESTED',
    //     payload: {
    //       uniqueEntity: 'post-auth-notification',
    //       context: sessionDialogContext.postAuthNotification,
    //       data: {
    //         is_verified: session.payload.is_verified,
    //         isConnectToShopifyStore: !!result.response.headers.rurl
    //       }
    //     }
    //   })
    // }

    if (result.response.headers.rurl) {
      const headers = result.response.headers;

      if (headers['rurl-type'] === 'success') {
        history.push(headers.rurl);
        yield (0, _reduxSaga.delay)(4000);
        yield (0, _effects.put)((0, _actions.ShowSuccess)({
          message: `Shopify Shop Link Successful`
        }));
      } else {
        history.push(headers.rurl);
        yield (0, _reduxSaga.delay)(4000);
        yield (0, _effects.put)((0, _actions.ShowError)({
          message: window.atob(headers['rurl-message'])
        }));
      }
    }
  }
}

function* PreAuthUser({
  type,
  payload = {}
}) {
  const dialogData = yield (0, _effects.select)(_tools2.getState.bind('global.dialog')) || {};
  const isVisible = dialogData.isVisible,
        data = dialogData.data,
        action = dialogData.action;
  const _payload$shouldCheck = payload.shouldCheck,
        shouldCheck = _payload$shouldCheck === void 0 ? true : _payload$shouldCheck,
        toDispatch = payload.action;

  if (isVisible && action === 'design_editor') {
    if (shouldCheck && data.modified) {
      if (!data.showConfirm) {
        yield (0, _effects.put)((0, _actions.SetDialogData)({
          showConfirm: true,
          confirmAction: {
            type,
            payload: {
              shouldCheck: false,
              action: toDispatch
            },
            isCloseEditorDialogAfterConfirmation: true
          }
        }));
      }

      return;
    }
  }

  yield (0, _reduxSaga.delay)(0);
  yield (0, _effects.put)((0, _actions.HideDialog)());
  yield (0, _reduxSaga.delay)(1000);
  yield (0, _effects.put)(toDispatch);
}

function* LogoutUser() {
  yield (0, _effects.put)((0, _actions.DialogUpdating)());
  const config = {
    method: 'put',
    url: `/${sessionEntity}/logout`
  };
  const result = yield (0, _effects.call)(_axios.default, config, _types.LOGOUT_USER);
  history.replaceState('/create', {});
  yield (0, _effects.all)([(0, _effects.put)(result), (0, _effects.put)((0, _actions.DialogUpdated)()), (0, _effects.put)({
    type: _types.HIDE_DIALOG
  })]); // const history = getHistory()
  // history.push('/')

  window.location.href = `${window.config.admin_experience_app_url}/auth/logout`;
}

function* UpdateUserSession({
  payload
}) {
  yield (0, _effects.put)((0, _actions.DialogUpdating)());
  const id = payload.id;
  const config = {
    method: 'put',
    url: `/${sessionEntity}`,
    data: _objectSpread(_objectSpread({}, (0, _omit.default)(payload, ['access_token', 'authenticated'])), {}, {
      metadata: {
        initiated_by: id
      }
    })
  };
  const result = yield (0, _effects.call)(_axios.default, config, _types.UPDATE_USER_SESSION);
  const promises = [(0, _effects.put)(result), (0, _effects.put)((0, _actions.DialogUpdated)())];

  if (result.type === _types.UPDATE_USER_SESSION) {
    result.payload = payload;
    promises.push((0, _effects.put)({
      type: _types.HIDE_DIALOG
    }), (0, _effects.put)((0, _actions.ShowSuccess)({
      message: `toast_profile`,
      action: 'postfix_successfully_updated'
    })));
  }

  yield (0, _effects.all)(promises);
}

function* UpdateCompanySession({
  payload
}) {
  yield (0, _effects.put)((0, _actions.DialogUpdating)());
  const id = payload.id;

  const data = _objectSpread(_objectSpread({}, payload), {}, {
    metadata: {
      initiated_by: id
    }
  });

  const config = {
    method: 'put',
    url: `/company`,
    data
  };
  const result = yield (0, _effects.call)(_axios.default, config, _types.UPDATE_COMPANY_SESSION);
  const promises = [yield (0, _effects.put)(result), yield (0, _effects.put)((0, _actions.DialogUpdated)())];

  if (result.type === _types.UPDATE_COMPANY_SESSION) {
    promises.push(yield (0, _effects.put)({
      type: _types.HIDE_DIALOG
    }), yield (0, _effects.put)((0, _actions.ShowSuccess)({
      message: `toast_company`,
      action: 'postfix_successfully_updated'
    })));
  }

  yield (0, _effects.all)(promises);
}

function* UpdateUserPreferences({
  payload
}) {
  yield (0, _effects.all)([(0, _effects.put)({
    type: 'PREFERENCES_UPDATING'
  }), (0, _effects.put)((0, _actions.DialogUpdating)())]);
  const id = payload.id,
        user_id = payload.user_id,
        logged_in_id = payload.logged_in_id,
        action_type = payload.action_type,
        preferences = payload.preferences;

  const data = _objectSpread(_objectSpread({}, payload), {}, {
    metadata: {
      initiated_by: id
    }
  });

  const config = {
    method: 'put',
    url: `/${sessionEntity}`,
    data: (0, _omit.default)(data, ['user_id', 'action_type', 'access_token', 'authenticated'])
  };
  const result = yield (0, _effects.call)(_axios.default, config, _types.UPDATE_USER_PREFERENCES);
  const promises = [(0, _effects.put)(result), (0, _effects.put)({
    type: 'PREFERENCES_UPDATED'
  }), (0, _effects.put)((0, _actions.DialogUpdated)())];

  if (result.type === _types.UPDATE_USER_PREFERENCES) {
    // --- update preferences ---
    result.payload.preferences = preferences;
    promises.push((0, _effects.put)({
      type: _types.HIDE_DIALOG
    }), (0, _effects.put)(result));

    if (action_type === 'switch_company') {
      const push_query = logged_in_id === user_id ? '' : `?user_id=${user_id}`;
      window.open(`${push_query}`);
    }

    if (action_type === 'reset_customization') {
      promises.push((0, _effects.put)((0, _actions.ShowSuccess)({
        message: `toast_company`,
        action: 'postfix_successfully_updated'
      })));
    } else if (action_type !== 'switch_company') {
      const filters = yield (0, _effects.select)(_tools2.getState.bind('global.session.data.preferences.filters')) || [];
      const action = filters.length > ((0, _get.default)(payload, 'preferences.filters') || []).length ? 'deleted' : 'updated';
      promises.push((0, _effects.put)((0, _actions.ShowSuccess)({
        message: `toast_user_filters`,
        action: `postfix_successfully_${action}`
      })));
    }
  }

  yield (0, _effects.all)(promises);
}

function* ChangeUserPassword({
  payload
}) {
  const _payload2 = payload,
        action = _payload2.action,
        isSetPassword = _payload2.isSetPassword;
  payload = (0, _omit.default)(payload, ['isSetPassword']); // --- having action means it comes from dialog

  if (action) {
    yield (0, _effects.put)((0, _actions.DialogUpdating)());
  } else {
    yield (0, _effects.put)({
      type: _types.PROCESSING_USER
    });
  }

  let tokenData = payload.artist_id ? _objectSpread({}, payload) : null;

  let userData = _objectSpread({}, payload);

  if (payload.artist_id) {
    userData = {
      id: payload.artist_id,
      password: payload.password,
      status: 'Active'
    };
  }

  const config1 = {
    method: 'put',
    url: `/${sessionEntity}/password`,
    data: (0, _omit.default)(userData, ['action', 'access_token', 'authenticated'])
  };
  const result1 = yield (0, _effects.call)(_axios.default, config1, _types.CHANGE_USER_PASSWORD);

  if (!tokenData || !result1.payload.updated) {
    const promises = [(0, _effects.put)(result1)]; // --- having action means it comes from dialog

    if (action) {
      promises.push((0, _effects.put)((0, _actions.DialogUpdated)()), (0, _effects.put)({
        type: _types.HIDE_DIALOG
      }), (0, _effects.put)((0, _actions.ShowSuccess)({
        message: `Password Successfully Updated`,
        action: 'postfix_successfully_updated'
      })));
    }

    yield (0, _effects.all)(promises);
    return;
  }

  tokenData = (0, _omit.default)(tokenData, ['artist_id', 'token', 'password']); // For validate token

  const config = {
    method: 'put',
    url: `/token`,
    data: tokenData
  };
  const result2 = yield (0, _effects.call)(_axios.default, config, _types.CHANGE_USER_PASSWORD);

  if (result2.type === 'CHANGE_USER_PASSWORD') {
    yield (0, _effects.put)((0, _actions.ShowSuccess)({
      message: isSetPassword ? 'Password successfully set.' : 'Password successfully changed.'
    }));
  }

  yield (0, _effects.all)([(0, _effects.put)(result1), (0, _effects.put)(result2)]);
}

function* ValidateToken({
  payload
}) {
  const config = {
    method: 'get',
    url: `/utility/token/${payload}/validate`
  };
  const result = yield (0, _effects.call)(_axios.default, config, _types.VALIDATE_TOKEN);
  yield (0, _effects.put)(result);
}

function* UpdateUsedToken(payload) {
  const config = {
    method: 'put',
    url: `/utility/token`,
    data: payload
  };
  const result = yield (0, _effects.call)(_axios.default, config, _types.UPDATE_USED_TOKEN);
  yield (0, _effects.put)(result);
}

function* SendResetPasswordEmail({
  payload
}) {
  const _payload$isHideDialog3 = payload.isHideDialog,
        isHideDialog = _payload$isHideDialog3 === void 0 ? true : _payload$isHideDialog3,
        callback = payload.callback,
        onHideDialog = payload.onHideDialog;
  yield (0, _effects.all)([(0, _effects.put)((0, _actions.DialogUpdating)()), (0, _effects.put)({
    type: _types.CLEAR_ERROR
  })]);
  yield (0, _effects.put)({
    type: 'SENDING_RESET_PASSWORD_EMAIL'
  });
  const config = {
    method: 'put',
    url: `/${sessionEntity}/forget/password`,
    data: (0, _omit.default)(payload, ['action', 'isHideDialog', 'callback', 'onHideDialog', 'access_token', 'authenticated'])
  };
  const result = yield (0, _effects.call)(_axios.default, config, _types.SEND_RESET_PASSWORD_EMAIL);
  const promises = [];

  if (result.type === _types.SEND_RESET_PASSWORD_EMAIL) {
    promises.push((0, _effects.put)((0, _actions.ShowSuccess)({
      message: `Instructions has been sent to your email [${payload.email}].`
    })), // put({ type: HIDE_DIALOG }),
    (0, _effects.put)((0, _actions.DialogUpdated)()));

    if (callback) {
      callback();
    }
  }

  promises.push((0, _effects.put)(result));
  yield (0, _effects.all)(promises);
  yield (0, _effects.put)({
    type: 'SENDING_RESET_PASSWORD_EMAIL_DONE'
  });
}

function* UploadCoverPhotos({
  payload
}) {
  yield (0, _effects.put)({
    type: _types.COVER_PHOTOS_UPLOADING
  });
  yield (0, _effects.put)((0, _actions.DialogUpdating)());
  const id = payload.id,
        files = payload.files,
        callback = payload.callback;

  try {
    const upload_config = {
      files,
      key: 'cover_photo_path',
      uniqueEntity: 'artist',
      entity_id: id
    };
    const upload_result = yield (0, _effects.call)(_multipleUpload.default, upload_config);

    if (upload_result && upload_result.success) {
      const cover_photos = upload_result.files.map(file => {
        const file_name = file.file_name,
              file_path = file.file_path,
              thumbnail_path = file.thumbnail_path;
        return {
          id: file.id,
          file_name,
          cover_photo_path: file_path,
          cover_photo_thumbnail_path: thumbnail_path
        };
      });
      yield (0, _effects.put)((0, _actions.DialogUpdated)());

      if (callback) {
        callback({
          cover_photos
        });
      } // yield put({ type: UPLOAD_COVER_PHOTOS, payload: { cover_photos } })

    }
  } catch (err) {}
}

function* UploadProfilePicture({
  payload
}) {
  yield (0, _effects.put)({
    type: _types.PROFILE_PICTURE_UPDATING
  });
  yield (0, _effects.put)({
    type: _types.UPLOAD_PROFILE_PICTURE,
    payload
  });
  yield (0, _effects.put)((0, _actions.ShowSuccess)({
    message: 'Profile picture successfully changed.'
  }));
  yield (0, _effects.put)({
    type: _types.HIDE_DIALOG
  });
}

function* UploadTempProfilePicture({
  payload
}) {
  yield (0, _effects.put)((0, _actions.DialogUpdating)());
  yield (0, _effects.put)({
    type: _types.TEMP_PROFILE_PICTURE_UPDATING
  });
  const id = payload.id,
        imageFile = payload.imageFile,
        callback = payload.callback,
        key = payload.key;

  const config = _objectSpread(_objectSpread({
    files: [imageFile]
  }, key && {
    key
  }), {}, {
    uniqueEntity: 'artist',
    entity_id: id,
    chunking: {
      partSize: 5000000,
      concurrent: {
        enabled: true
      }
    }
  });

  const result = yield (0, _effects.call)(_uploadFile.default, config);

  if (result && result.success) {
    if (callback) {
      callback(result);
    }

    yield (0, _effects.put)((0, _actions.DialogUpdated)());
    yield (0, _effects.put)({
      type: _types.TEMP_PROFILE_PICTURE_UPDATED
    });
  }
}

function* GetSearchResult({
  payload
}) {
  const payloadData = (0, _omit.default)(payload, 'callback');
  const config = {
    method: 'post',
    url: `/search`,
    data: payloadData
  };
  const result = yield (0, _effects.call)(_axios.default, config, _types.GET_SEARCH_RESULT);

  if (result.type === _types.GET_SEARCH_RESULT) {
    yield (0, _effects.put)({
      type: _types.GET_SEARCH_RESULT,
      payload: _objectSpread(_objectSpread({}, payloadData), result.payload)
    });

    if (payload.callback) {
      payload.callback();
    }

    return;
  }

  yield (0, _effects.put)(result);
}

function* Subscribe({
  payload
}) {
  // const { callback, email, isJoinWatitingList, section_id } = payload;
  // if (isJoinWatitingList) {
  //   yield put({ type: 'SESSION_UPDATING' });
  // }
  // yield put({ type: 'PAGE_PROCESSING' });
  // const config = {
  //   method: 'POST',
  //   url: `/subscribe`,
  //   data: {
  //     email,
  //     metadata: {
  //       initiated_by: 'subscribe'
  //     }
  //   }
  // };
  // const result = yield call(axios, config, 'SUBSCRIBE');
  // if (result.type !== 'SUBSCRIBE' && isJoinWatitingList) {
  //   yield put({
  //     type: 'SET_SUBSCRIPTION_ERROR',
  //     payload: {
  //       ...result.payload,
  //       section_id
  //     }
  //   })
  //   yield put({ type: 'PAGE_DONE_PROCESSING' });
  //   yield put({ type: 'SESSION_UPDATED' });
  //   return
  // }
  // const promises = [put(result)];
  // if (result.type === 'SUBSCRIBE') {
  //   if (isJoinWatitingList) {
  //     yield put({ type: 'SESSION_UPDATED' });
  //   } else {
  //     yield put(ShowSuccess('Email successfully subscribed.'));
  //   }
  // }
  // yield put({ type: 'PAGE_DONE_PROCESSING' });
  // yield all(promises);
  // if (callback) {
  //   callback(result);
  // }
  const email = payload.email;
  yield (0, _effects.put)({
    type: 'SUBSCRIBE_LOADING'
  });
  const config = {
    method: 'POST',
    url: `/subscribe`,
    data: {
      email,
      metadata: {
        initiated_by: 'subscribe'
      }
    }
  };
  const result = yield (0, _effects.call)(_axios.default, config, 'SUBSCRIBE');

  if (result.type !== 'SUBSCRIBE') {
    yield (0, _effects.put)({
      type: 'SUBSCRIBE_LOADING_DONE'
    });
    yield (0, _effects.put)({
      type: 'SUBSCRIBE',
      payload: {
        response: 'failed',
        message: result.payload.message
      }
    });
    return;
  }

  yield (0, _effects.put)({
    type: 'SUBSCRIBE_LOADING_DONE'
  });
  yield (0, _effects.put)({
    type: 'SUBSCRIBE',
    payload: {
      response: 'success',
      message: ''
    }
  });
}

function* GetMoreDesignsFromArtist({
  payload
}) {
  const id = payload.id,
        artist_id = payload.artist_id;
  const config = {
    method: 'post',
    url: `/product/grid`,
    data: {
      must_not: [{
        key: 'id',
        value: [id]
      }, {
        key: 'hidden_product',
        value: [true]
      }],
      filter: [{
        key: 'status',
        value: ['Approved']
      }, {
        key: 'visibility',
        value: ['Public']
      }, {
        key: 'artist_id',
        value: [artist_id]
      }, {
        key: 'is_locked',
        value: [false]
      }],
      sort_column: 'created_date',
      sort_direction: 'desc'
    }
  };
  const result = yield (0, _effects.call)(_axios.default, config, 'GET_MORE_DESIGNS_FROM_ARTIST');

  if (result.type === 'GET_MORE_DESIGNS_FROM_ARTIST') {
    const _result$payload$data = result.payload.data,
          data = _result$payload$data === void 0 ? [] : _result$payload$data;
    yield (0, _effects.put)({
      type: 'SET_FULL_DIALOG_DATA',
      payload: {
        artist_designs: data
      }
    });
    return;
  }

  yield (0, _effects.put)(result);
}

function* GetSimiarDesigns({
  payload
}) {
  const design_tags = payload.design_tags,
        artist_id = payload.artist_id,
        product_category_id = payload.product_category_id;
  const config = {
    method: 'post',
    url: `/product/grid`,
    data: {
      must_not: [{
        key: 'hidden_product',
        value: [true]
      }, {
        key: 'artist_id',
        value: [artist_id]
      }],
      filter: [{
        key: 'status',
        value: ['Approved']
      }, {
        key: 'visibility',
        value: ['Public']
      }, {
        key: 'design_tags',
        value: design_tags
      }, {
        key: 'product_category_id',
        value: [product_category_id]
      }, {
        key: 'is_locked',
        value: [false]
      }],
      sort_column: 'created_date',
      sort_direction: 'desc'
    }
  };
  const result = yield (0, _effects.call)(_axios.default, config, 'GET_MORE_DESIGNS_FROM_ARTIST');

  if (result.type === 'GET_MORE_DESIGNS_FROM_ARTIST') {
    const _result$payload$data2 = result.payload.data,
          data = _result$payload$data2 === void 0 ? [] : _result$payload$data2;
    yield (0, _effects.put)({
      type: 'SET_FULL_DIALOG_DATA',
      payload: {
        similar_designs: data
      }
    });
    return;
  }

  yield (0, _effects.put)(result);
}

function* SetUserPassword({
  payload
}) {
  const callback = payload.callback,
        isSetPassword = payload.isSetPassword;
  const trimmed_payload = (0, _omit.default)(payload, 'callback');
  yield (0, _effects.put)({
    type: 'SET_PASSWORD_PROCESSING',
    payload: true
  });
  yield (0, _effects.put)((0, _actions.ChangeUserPassword)(trimmed_payload));

  if (!isSetPassword) {
    return;
  }

  yield (0, _effects.take)('CHANGE_USER_PASSWORD');
  const setPasswordResult = yield (0, _effects.select)(_tools2.getState.bind('global.session.validated')) || {};

  if (setPasswordResult) {
    // login user
    const metadata = (0, _getMetadata.default)();
    const loginParams = {
      method: 'post',
      url: `/${sessionEntity}/login`,
      data: _objectSpread(_objectSpread(_objectSpread({}, (0, _pick.default)(setPasswordResult, 'email')), (0, _pick.default)(payload, 'password')), {}, {
        device_type: metadata.platform,
        metadata
      })
    };
    yield (0, _effects.put)({
      type: 'PAGE_PROCESSING'
    });
    const loginResult = yield (0, _effects.call)(_axios.default, loginParams, _types.LOGIN_USER);

    if (loginResult.type === _types.LOGIN_USER) {
      yield (0, _effects.put)({
        payload: {
          data: loginResult.payload
        },
        type: _types.LOGIN_USER
      });
      yield (0, _effects.put)({
        type: 'PAGE_DONE_PROCESSING'
      });

      if (callback) {
        callback(loginResult.payload);
      }
    }

    yield (0, _effects.put)({
      type: 'SET_PASSWORD_PROCESSING',
      payload: true
    }); // yield put(loginResult)
  }
}

function* ResendVerificationEmail({
  payload
}) {
  const callback = payload.callback,
        _payload$trigger_sour = payload.trigger_source,
        trigger_source = _payload$trigger_sour === void 0 ? 'dialog' : _payload$trigger_sour,
        _payload$is_show_toas = payload.is_show_toast,
        is_show_toast = _payload$is_show_toas === void 0 ? true : _payload$is_show_toas;
  if (trigger_source === 'dialog') yield (0, _effects.put)((0, _actions.DialogUpdating)());
  if (trigger_source === 'page') yield (0, _effects.put)({
    type: 'PAGE_PROCESSING'
  });
  const session_id = yield (0, _effects.select)(_tools2.getState.bind('global.session.data.id'));
  const config = {
    method: 'POST',
    url: `/customer/verify`,
    data: {
      id: session_id
    }
  };
  const result = yield (0, _effects.call)(_axios.default, config, 'RESEND_VERIFICATION_EMAIL');

  if (result.type !== 'RESEND_VERIFICATION_EMAIL') {
    yield (0, _effects.put)(result);
    if (trigger_source === 'dialog') yield (0, _effects.put)((0, _actions.DialogUpdated)());
    if (trigger_source === 'page') yield (0, _effects.put)({
      type: 'PAGE_DONE_PROCESSING'
    });
    return;
  }

  if (is_show_toast) {
    yield (0, _effects.put)((0, _actions.ShowSuccess)('Verification email successfully sent.'));
  }

  if (callback) {
    callback();
  }

  if (trigger_source === 'dialog') yield (0, _effects.put)((0, _actions.DialogUpdated)());
  if (trigger_source === 'page') yield (0, _effects.put)({
    type: 'PAGE_DONE_PROCESSING'
  }); // yield put(result)
}

function* DisableFirstUsePrompt() {
  const session_data = yield (0, _effects.select)(_tools2.getState.bind('global.session.data'));
  const with_metadata = (0, _omit.default)((0, _tools2.setMetadata)(session_data, session_data.id), _artistOmittedFields.default);
  const config = {
    method: 'PUT',
    url: `/artist`,
    data: _objectSpread(_objectSpread({}, with_metadata), {}, {
      is_first_use: false
    })
  };
  const result = yield (0, _effects.call)(_axios.default, config, 'DISABLE_FIRST_USE_PROMPT');

  if (result.type === 'DISABLE_FIRST_USE_PROMPT') {
    yield (0, _effects.put)((0, _actions.UpdateSessionData)(result.payload));
    return;
  }

  yield (0, _effects.put)(result);
}

function* DisableLogoutPrompt() {
  const session_data = yield (0, _effects.select)(_tools2.getState.bind('global.session.data'));
  const with_metadata = (0, _omit.default)((0, _tools2.setMetadata)(session_data, session_data.id), _artistOmittedFields.default);
  const config = {
    method: 'PUT',
    url: `/artist`,
    data: _objectSpread(_objectSpread({}, with_metadata), {}, {
      show_logout_dialog: false
    })
  };
  const result = yield (0, _effects.call)(_axios.default, config, 'DISABLE_LOGOUT_PROMPT');

  if (result.type === 'DISABLE_LOGOUT_PROMPT') {
    yield (0, _effects.put)((0, _actions.UpdateSessionData)(result.payload));
    return;
  }

  yield (0, _effects.put)(result);
}

function* ShowFirstVisitNotification() {
  _store.default.set('isFirstVisitNotificationVisible', true);
}

function* DismissFirstVisitNotification() {
  _store.default.set('isFirstVisitNotificationVisible', false);

  yield (0, _effects.put)((0, _actions.HideDialog)());
}

function* SendFeedback({
  payload
}) {
  const callback = payload.callback,
        rest = _objectWithoutPropertiesLoose(payload, ["callback"]);

  const config = {
    url: '/feedback',
    method: 'POST',
    data: rest
  };
  const result = yield (0, _effects.call)(_axios.default, config, 'SEND_FEEDBACK');

  if (result.type === 'SEND_FEEDBACK') {
    yield (0, _effects.put)((0, _actions.ShowSuccess)({
      message: 'Feedback successfully submitted.'
    }));

    if (callback) {
      callback();
    }
  }

  yield (0, _effects.put)({
    type: 'FEEDBACK_LOADING_DONE'
  });
  yield (0, _effects.put)(result);
}

function* FetchCreatorDetailsOnClick({
  payload
}) {
  const id = payload.id,
        callback = payload.callback;
  yield (0, _effects.put)({
    type: 'FETCHING_CREATOR_DETAILS_ON_CLICK_LOADING'
  });
  const config = {
    method: 'GET',
    url: `/artist/${id}`
  };
  const result = yield (0, _effects.call)(_axios.default, config, 'FETCH_CREATOR_DETAILS_ON_CLICK');

  if (result.type !== 'FETCH_CREATOR_DETAILS_ON_CLICK') {
    yield (0, _effects.put)({
      type: 'FETCHING_CREATOR_DETAILS_ON_CLICK_DONE'
    });

    if (callback) {
      callback({
        error: true
      });
    }

    return;
  }

  if (callback) {
    callback(result.payload);
  }
}

function* FetchProductRelatedDataOnClick({
  payload
}) {
  const artist_id = payload.artist_id,
        style_type_id = payload.style_type_id,
        callback = payload.callback; // yield put({ type: 'FETCHING_CREATOR_DETAILS_ON_CLICK_LOADING' })
  //  GET STYLE TYPE

  const styleTypeConfig = {
    method: 'GET',
    url: `/style-types/${style_type_id}`
  };
  const styleTypeResult = yield (0, _effects.call)(_axios.default, styleTypeConfig, 'FETCH_PRODUCT_RELATED_DATA_STYLE_TYPE');

  if (styleTypeResult.type !== 'FETCH_PRODUCT_RELATED_DATA_STYLE_TYPE') {
    if (callback) {
      callback({
        error: true
      });
    }
  } //  GET ARTIST DETAILS


  const artistConfig = {
    method: 'GET',
    url: `/artist/${artist_id}`
  };
  const artistResult = yield (0, _effects.call)(_axios.default, artistConfig, 'FETCH_PRODUCT_RELATED_DATA_ARTIST');

  if (artistResult.type !== 'FETCH_PRODUCT_RELATED_DATA_ARTIST') {
    if (callback) {
      callback({
        error: true
      });
    }
  }

  const style_type_name = styleTypeResult.payload.name;
  const _artistResult$payload = artistResult.payload,
        username = _artistResult$payload.username,
        first_name = _artistResult$payload.first_name,
        last_name = _artistResult$payload.last_name,
        code = _artistResult$payload.code;
  const related_data = {
    style_type_name,
    username,
    first_name,
    last_name,
    code
  };

  if (callback) {
    callback(related_data);
  }
}

function* SetProductsPageParams({
  payload
}) {
  _store.default.set('products-params', payload);
}

function* ResetUserPassword({
  payload
}) {
  const _payload3 = payload,
        action = _payload3.action,
        isSetPassword = _payload3.isSetPassword,
        callback = _payload3.callback,
        errorCallback = _payload3.errorCallback;
  payload = (0, _omit.default)(payload, ['isSetPassword']);
  yield (0, _effects.put)({
    type: _types.PROCESSING_USER
  });
  let tokenData = payload.artist_id ? _objectSpread({}, payload) : null;

  let userData = _objectSpread({}, payload);

  if (payload.artist_id) {
    userData = {
      id: payload.artist_id,
      password: payload.password,
      status: 'Active',
      token: payload.token
    };
  }

  const config1 = {
    method: 'put',
    url: `/artist/reset-password`,
    data: (0, _omit.default)(userData, ['action', 'access_token', 'authenticated'])
  };
  const result1 = yield (0, _effects.call)(_axios.default, config1, _types.CHANGE_USER_PASSWORD);

  if (result1.type !== _types.CHANGE_USER_PASSWORD) {
    const _session = session,
          _session$payload2 = _session.payload,
          errorPayload = _session$payload2 === void 0 ? {} : _session$payload2;
    const message = errorPayload.message;

    if (errorCallback) {
      errorCallback({
        errorMessage: message || 'Oops!, something went wrong. Please try again.'
      });
    }
  }

  if (!tokenData || !result1.payload.updated) {
    const promises = [(0, _effects.put)(result1)]; // --- having action means it comes from dialog

    if (action) {
      promises.push((0, _effects.put)((0, _actions.DialogUpdated)()), (0, _effects.put)({
        type: _types.HIDE_DIALOG
      }), (0, _effects.put)((0, _actions.ShowSuccess)({
        message: `Password Successfully Updated`,
        action: 'postfix_successfully_updated'
      })));
    }

    yield (0, _effects.all)(promises);
    return;
  }

  if (callback) {
    callback();
  }

  if (!isSetPassword) {
    yield (0, _effects.put)((0, _actions.ShowSuccess)({
      message: 'Password successfully changed.'
    }));
  } else {
    yield (0, _effects.put)((0, _actions.ShowSuccess)({
      message: 'Password successfully set.'
    }));
  }

  yield (0, _effects.all)([(0, _effects.put)(result1)]);
}

function* UpdateProfilePhotoSession({
  payload
}) {
  yield (0, _effects.put)((0, _actions.DialogUpdating)());
  const _payload4 = payload,
        id = _payload4.id,
        callback = _payload4.callback,
        cancel = _payload4.cancel;
  payload = (0, _tools2.setMetadata)(payload, id);
  let shaped_payload = (0, _omit.default)(payload, _artistOmittedFields.default);

  if (payload.has_placeholder_image && !payload.file_path) {
    const placeholder_blob = yield (0, _effects.call)(_tools2.fileToBlob, (0, _tools2.generateRandomProfileImage)().path);
    const placeholder_file = new File([placeholder_blob], `${payload.id}-${(0, _v.default)()}.png`, {
      type: 'png',
      id: (0, _v.default)()
    });
    const uploadPlaceholderConfig = {
      files: [placeholder_file],
      uniqueEntity: 'artist',
      entity_id: payload.id,
      chunking: {
        partSize: 5000000,
        concurrent: {
          enabled: true
        }
      }
    };
    const profileImageResult = yield (0, _effects.call)(_uploadFile.default, uploadPlaceholderConfig);

    const _ref = profileImageResult || {},
          file_path = _ref.file_path,
          thumbnail_path = _ref.thumbnail_path;

    shaped_payload = _objectSpread(_objectSpread({}, shaped_payload), {}, {
      file_path,
      thumbnail_path
    });
  }

  const config = {
    method: 'PUT',
    url: `/artist`,
    data: shaped_payload
  };
  const result = yield (0, _effects.call)(_axios.default, config, 'UPDATE_PROFILE_PHOTO_SESSION');

  if (result.type === 'UPDATE_PROFILE_PHOTO_SESSION') {
    if (!cancel) {
      yield (0, _effects.put)((0, _actions.ShowSuccess)("Creator's Profile successfully updated."));
    }

    if (callback) {
      callback((0, _omit.default)(result.payload, _artistOmittedFields.default));
    }

    yield (0, _effects.put)((0, _actions.HideDialog)());
  }

  yield (0, _effects.put)(result);
}

module.exports = function* session() {
  // GET
  yield (0, _effects.takeLatest)(_types.GET_SESSION_COMPANY_DETAILS + _types.REQUESTED, GetSessionCompanyDetails);
  yield (0, _effects.takeLatest)(_types.VALIDATE_TOKEN + _types.REQUESTED, ValidateToken);
  yield (0, _effects.takeLatest)(_types.LOGIN_USER + _types.REQUESTED, LoginUser);
  yield (0, _effects.takeLatest)(_types.REGISTER_USER + _types.REQUESTED, RegisterUser);
  yield (0, _effects.takeLatest)(_types.SEND_RESET_PASSWORD_EMAIL + _types.REQUESTED, SendResetPasswordEmail);
  yield (0, _effects.takeLatest)(`GET_MORE_DESIGNS_FROM_ARTIST${_types.REQUESTED}`, GetMoreDesignsFromArtist);
  yield (0, _effects.takeLatest)(`GET_SIMILAR_DESIGNS${_types.REQUESTED}`, GetSimiarDesigns); // PUT

  yield (0, _effects.takeLatest)(_types.LOGOUT_USER + _types.REQUESTED, LogoutUser);
  yield (0, _effects.takeLatest)(_types.UPDATE_USER_SESSION + _types.REQUESTED, UpdateUserSession);
  yield (0, _effects.takeLatest)(_types.UPDATE_COMPANY_SESSION + _types.REQUESTED, UpdateCompanySession);
  yield (0, _effects.takeLatest)(_types.UPDATE_USER_PREFERENCES + _types.REQUESTED, UpdateUserPreferences);
  yield (0, _effects.takeLatest)(_types.CHANGE_USER_PASSWORD + _types.REQUESTED, ChangeUserPassword);
  yield (0, _effects.takeLatest)(`SET_USER_PASSWORD${_types.REQUESTED}`, SetUserPassword);
  yield (0, _effects.takeLatest)(_types.UPDATE_USED_TOKEN + _types.REQUESTED, UpdateUsedToken);
  yield (0, _effects.takeLatest)(`PRE_AUTH${_types.REQUESTED}`, PreAuthUser);
  yield (0, _effects.takeLatest)(`DISABLE_FIRST_USE_PROMPT${_types.REQUESTED}`, DisableFirstUsePrompt);
  yield (0, _effects.takeLatest)(`DISABLE_LOGOUT_PROMPT${_types.REQUESTED}`, DisableLogoutPrompt);
  yield (0, _effects.takeLatest)(`UPDATE_PROFILE_PHOTO_SESSION${_types.REQUESTED}`, UpdateProfilePhotoSession); // POST

  yield (0, _effects.takeLatest)(_types.GET_SEARCH_RESULT + _types.REQUESTED, GetSearchResult);
  yield (0, _effects.takeLatest)(_types.UPLOAD_COVER_PHOTOS + _types.REQUESTED, UploadCoverPhotos);
  yield (0, _effects.takeLatest)(_types.UPLOAD_PROFILE_PICTURE + _types.REQUESTED, UploadProfilePicture);
  yield (0, _effects.takeLatest)(_types.UPLOAD_TEMP_PROFILE_PICTURE + _types.REQUESTED, UploadTempProfilePicture);
  yield (0, _effects.takeLatest)(`SUBSCRIBE${_types.REQUESTED}`, Subscribe);
  yield (0, _effects.takeLatest)(`RESEND_VERIFICATION_EMAIL${_types.REQUESTED}`, ResendVerificationEmail); //CUSTOM

  yield (0, _effects.takeLatest)(`SHOW_FIRST_VISIT_NOTIFICATION${_types.REQUESTED}`, ShowFirstVisitNotification);
  yield (0, _effects.takeLatest)(`DISMISS_FIRST_VISIT_NOTIFICATION${_types.REQUESTED}`, DismissFirstVisitNotification);
  yield (0, _effects.takeLatest)(`SEND_FEEDBACK${_types.REQUESTED}`, SendFeedback);
  yield (0, _effects.takeLatest)(`FETCH_CREATOR_DETAILS_ON_CLICK${_types.REQUESTED}`, FetchCreatorDetailsOnClick);
  yield (0, _effects.takeLatest)(`FETCH_PRODUCT_RELATED_DATA_ON_CLICK${_types.REQUESTED}`, FetchProductRelatedDataOnClick);
  yield (0, _effects.takeLatest)(`SET_PRODUCTS_PAGE_PARAMS${_types.REQUESTED}`, SetProductsPageParams);
  yield (0, _effects.takeLatest)(`RESET_USER_PASSWORD${_types.REQUESTED}`, ResetUserPassword);
};